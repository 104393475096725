import React, { useState } from "react";
import ReactGA from "react-ga4";

import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import {
  getUnverifiedArtists,
  updateArtistProfile,
} from "../rtsvrf-actions/Actions";

import SelectArtist from "./shared/SelectArtist";

function VerifyArtist({ user, userIpData }) {
  const [loading, setLoading] = useState();

  const [claimComments, setClaimComments] = useState();
  const [vanityName, setVanityName] = useState();
  const [claimStatus, setClaimStatus] = useState(false);
  const [selectedArtist, setSelectedArtist] = useState([]);

  const [unverifiedArtists, setUnverifiedArtists] = useState([]);

  const fetchDefaultData = async () => {
    const list = await getUnverifiedArtists();
    setUnverifiedArtists(list);
  };
  useState(() => {
    fetchDefaultData();
  }, []);

  const handleSelectArtist = (selectedOption, name) => {
    console.log(selectedOption, "option", name);
    setSelectedArtist(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (!selectedArtist) {
      toast.error("Select artists first!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (!claimStatus) {
      toast.error("Claim status should be checked!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    ReactGA.event({
      category: "Verify artist profile",
      action: "click",
      label: "Verify artist profile",
    });

    console.log(selectedArtist, claimComments);

    const result = await updateArtistProfile(selectedArtist.rtsvrf_artist_id, {
      rtsvrf_profile_claim_status: claimStatus,
      ...(claimStatus && { artist_claim_timestamp: new Date() }),
      artist_vanity_name: vanityName,
      verification_timestamp: new Date(),
      claim_comments: claimComments,
    });

    setLoading(false);
    if (result?.error) {
      toast.error("Failed to verify artist! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Artist verified successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedArtist();
      setClaimComments();
      setClaimStatus();
      setVanityName();
      await logSuccess(
        `Artist profile verified - ${selectedArtist.artist_name}`,
        {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          details: `Artist verified - ${selectedArtist.artist_name}, ID - ${selectedArtist.rtsvrf_artist_uuid} Details: Comments - ${claimComments}, Vanity Name - ${vanityName}`,
          type: "Admin",
        }
      );

      await fetchDefaultData();
    }
  };

  return (
    <div className="my-2 lg:ml-0">
      <h3 className="text-center text-white text-xl my-2 font-bold">
        Manage Claims
      </h3>
      <form class="w-full max-w-lg bg-gray-200 shadow-xl rounded-lg p-8">
        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Select an artist to verify
          </label>
          <SelectArtist
            artistData={unverifiedArtists}
            name={"artist"}
            isMulti={false}
            internalSearchOnly={true}
            selectedArtist={selectedArtist}
            handleArtistSelection={handleSelectArtist}
          />
        </div>
        <div className="mb-1 w-full">
          <div className="w-full mb-1 flex items-center">
            <input
              id="web_search"
              type="checkbox"
              checked={claimStatus}
              onClick={() => setClaimStatus(!claimStatus)}
              class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
            />
            <label
              for="web_search"
              className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
            >
              Verify Artist Profile
            </label>
          </div>
        </div>
        <div className="mb-1 w-full">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Claim Comments
            </label>
          </div>

          <input
            className="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={claimComments ?? ""}
            onChange={(e) => setClaimComments(e.target.value)}
            placeholder="Enter Claim Comments..."
          />
        </div>
        <div className="mb-1 w-full">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Vanity Name
            </label>
          </div>

          <input
            className="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={vanityName ?? ""}
            onChange={(e) => setVanityName(e.target.value)}
            placeholder="Enter Vanity Name..."
          />
        </div>
      </form>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
        >
          {loading ? "Verifying..." : "Verify"}
        </button>
      </div>
    </div>
  );
}

export default VerifyArtist;
