import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router-dom";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import suprsend from "@suprsend/web-sdk";
import SentNotifications from "./SentNotifications";
import { getAvAdminMappingsById } from "../rtsvrf-actions/Actions";

const Navbar = ({ links, userIpData, user, buttons }) => {
  let navigate = useNavigate();
  const [userData, setUserData] = useState("");
  useEffect(() => {
    const fetchUser = async () => {
      const result = await getAvAdminMappingsById(user.id);
      setUserData(result);
    };
    if (user?.id) {
      fetchUser();
    }
  }, [user]);
  const logoutWithRedirect = async () => {
    ReactGA.event({
      category: "LogOut",
      action: "click",
      label: "LogOut",
    });

    const { error } = await supabase.auth.signOut();
    if (error) {
      toast.error("Something went wrong! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      suprsend.reset();
      await logSuccess("Log Out", {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        type: "Admin",
      });
      localStorage.clear();
      navigate("/");
    }
  };

  const goBack = (link) => {
    navigate(link);
  };

  return (
    <nav className="w-full p-4 rounded-md text-right flex md:flex-col items-center justify-between">
      <ol className="list-reset flex flex-wrap">
        {links?.map((e, i) => {
          if (i === links.length - 1) {
            return (
              <li
                key={1}
                className={e.link && "cursor-pointer"}
                onClick={() => e.link && goBack(e.link)}
              >
                <p className="text-white transition duration-150 ease-in-out">
                  {e.label}
                </p>
              </li>
            );
          } else {
            return (
              <>
                <li
                  key={i}
                  className={e.link && "cursor-pointer"}
                  onClick={() => e.link && goBack(e.link)}
                >
                  <p className="text-white transition duration-150 ease-in-out">
                    {e.label}
                  </p>
                </li>
                <li key={2}>
                  <span className="mx-2 text-white">/</span>
                </li>
              </>
            );
          }
        })}
      </ol>
      <div className="flex items-center">
        {buttons?.length &&
          buttons.map((btn) => (
            <button
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 mt-3 mr-2 border border-gray-400 rounded shadow"
              onClick={btn.onClick}
            >
              {btn.title}
            </button>
          ))}
        {userData?.fandata_delegated_artists?.length && (
          <SentNotifications
            userId={user?.id}
            subscriberId={user?.user_metadata?.suprsend_subscriber_id}
          />
        )}
        <button
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 mt-3 border border-gray-400 rounded shadow"
          onClick={() => logoutWithRedirect()}
        >
          Logout
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
