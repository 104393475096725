import moment from "moment";
import React, { useEffect, useState } from "react";
import UserIcon from "../../rtsvrf-assets/icons/user.png";
import Loading from "../Loading";

const FansTable = ({
  data,

  loading,
  countries,
  handleEditUser,
}) => {
  const onEditUser = (row) => {
    handleEditUser(row);
  };

  if (!data || loading) {
    return <Loading />;
  }
  if (data.length === 0) {
    return (
      <h3 className="text-center border border-solid rounded-md text-lg py-6 font-bold text-white">
        No Data Found
      </h3>
    );
  }

  return (
    <>
      <div class="w-full bg-gray-50 p-1 rounded-md relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Name
              </th>
              <th scope="col" class="px-6 py-3">
                fan account status
              </th>

              <th scope="col" class="px-6 py-3">
                Phone
              </th>
              <th scope="col" class="px-6 py-3">
                City
              </th>
              <th scope="col" class="px-6 py-3">
                Country
              </th>
              <th scope="col" class="px-6 py-3 text-center">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => {
              const country = countries?.find(
                (e) => e.iso2 === row?.temp_country
              )?.name;

              return (
                <>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <img
                        class="w-10 h-10 rounded-full"
                        src={row?.fan_picture_link ?? UserIcon}
                        alt="profile"
                      />
                      <div class="pl-3">
                        <div class="text-base font-semibold">
                          {row?.fan_name ?? "-"}
                        </div>
                        <div class="font-normal text-gray-500">
                          {row.fan_email}
                        </div>
                      </div>
                    </th>
                    <td class="px-6 py-4">
                      {" "}
                      {row?.fan_account_active ? "Active" : "Inactive"}
                    </td>

                    <td class="px-6 py-4">{row?.phone_number ?? "-"}</td>
                    <td class="px-6 py-4"> {row?.city ?? "-"}</td>
                    <td class="px-6 py-4">
                      {country ?? row?.temp_country ?? "-"}
                    </td>
                    <td
                      onClick={() => onEditUser(row)}
                      class="px-6 py-4 underline cursor-pointer font-semibold text-blue-500"
                    >
                      Edit user
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FansTable;
