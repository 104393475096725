import React, { useState, useEffect } from "react";
import Card from "./shared/Card";
import ArtistIcon from "../rtsvrf-assets/icons/artist.png";
import EditorIcon from "../rtsvrf-assets/icons/setting.png";
import GroupIcon from "../rtsvrf-assets/icons/people.png";
import FansIcon from "../rtsvrf-assets/icons/fans.png";
import ClaimIcon from "../rtsvrf-assets/icons/health-insurance.png";
import StatsIcon from "../rtsvrf-assets/icons/data-science.png";
import { useNavigate } from "react-router-dom";
import {
  canManageArtist,
  canManageArtistEditor,
  canManageClaims,
  canManageFans,
  canManageGroups,
  canManageStats,
} from "../rtsvrf-utils/AccessControls";

function LandingSection({ userIpData, user, adminProfile }) {
  useEffect(() => {
    console.log(userIpData, user, adminProfile);
  }, [userIpData, user, adminProfile]);
  let navigate = useNavigate();

  const handleArtist = () => {
    navigate(`/admin/${user.id}/artists`);
  };
  const handleArtistEditor = () => {
    navigate(`/admin/${user.id}/artist-editors`);
  };
  const handleLabelGroups = () => {
    navigate(`/admin/${user.id}/label-groups`);
  };
  const handleClaims = () => {
    navigate(`/admin/${user.id}/claim-verification`);
  };
  const handleStatistics = () => {
    navigate(`/admin/${user.id}/statistics`);
  };
  const handleFans = () => {
    navigate(`/admin/${user.id}/fans`);
  };

  return (
    <div className="flex flex-wrap items-center justify-around">
      {canManageArtist(adminProfile) && (
        <Card
          icon={ArtistIcon}
          title={"Add/Modify Artist"}
          handleClick={handleArtist}
        />
      )}
      {canManageFans(adminProfile) && (
        <Card icon={FansIcon} title={"Manage Fans"} handleClick={handleFans} />
      )}
      {canManageArtistEditor(adminProfile) && (
        <Card
          icon={EditorIcon}
          title={"Add/Modify Artist Editor"}
          handleClick={handleArtistEditor}
        />
      )}
      {canManageGroups(adminProfile) && (
        <Card
          icon={GroupIcon}
          title={"Add/Modify Groups & Links"}
          handleClick={handleLabelGroups}
        />
      )}
      {canManageClaims(adminProfile) && (
        <Card
          icon={ClaimIcon}
          title={"Manage Claims & Verification"}
          handleClick={handleClaims}
        />
      )}
      {canManageStats(adminProfile) && (
        <Card
          icon={StatsIcon}
          title={"Analytics"}
          handleClick={handleStatistics}
        />
      )}
    </div>
  );
}

export default LandingSection;
