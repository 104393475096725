import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import { insertArtistBasicProfile } from "../rtsvrf-actions/Actions";
import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { SPOTIFY_LINK } from "../rtsvrf-utils/Constants";

function CreateArtistForm({ user, userIpData }) {
  const [loading, setLoading] = useState();
  const [editableFieldsStatus, setEditableFieldsStatus] = useState({
    published: false,
  });
  const [profile, setProfile] = useState();

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };
  const handleFieldsStatus = (name, value) => {
    setEditableFieldsStatus({ ...editableFieldsStatus, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (
      !profile ||
      !profile.artist_name ||
      (!profile.artist_spotfy_id && !profile.artist_aplmus_id)
    ) {
      toast.error("Fill up Artist name and At least one streaming platofrm", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    ReactGA.event({
      category: "Create Artist Profile",
      action: "click",
      label: "Create Artist Profile",
    });

    const profileResult = await insertArtistBasicProfile(
      {
        ...profile,
        artist_origin_fan: "AVADMIN",
        ...(profile?.artist_spotfy_id
          ? {
              artist_spotfy_link: `${SPOTIFY_LINK}${profile?.artist_spotfy_id}`,
            }
          : {}),
      },
      editableFieldsStatus
    );

    setLoading(false);
    if (profileResult?.error) {
      toast.error(profileResult.message ?? "Failed to add artist! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (profileResult?.message) {
        await logSuccess(
          `${profileResult.message} - ${profile?.artist_name || "Artist"}`,
          {
            ...userIpData,
            email: user.email,
            timestamp: new Date(),
            details: `Name: ${profile?.artist_name}, Spotify: ${
              profile?.artist_spotfy_id
            }, Spotify_Link: ${`${SPOTIFY_LINK}${profile?.artist_spotfy_id}`}, Apple Music: ${
              profile?.artist_aplmus_id
            }, Publish: ${editableFieldsStatus.published}`,
            type: "Admin",
          }
        );
      }
    } else {
      setProfile();
      toast.success("Artist added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(`New Artist ${profile?.artist_name || "Artist"} Added`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: `Name: ${profile?.artist_name}, Spotify: ${
          profile?.artist_spotfy_id
        }, Spotify_Link: ${`${SPOTIFY_LINK}${profile?.artist_spotfy_id}`}, Apple Music: ${
          profile?.artist_aplmus_id
        }, Publish: ${editableFieldsStatus.published}`,
        type: "Admin",
      });
    }
  };

  return (
    <div className="my-2 ml-5 lg:ml-0">
      <h3 className="text-center text-xl my-2 font-bold text-white">
        Create Artist
      </h3>
      <form className="flex items-center flex-col justify-center bg-gray-200 shadow-xl rounded-lg p-8">
        <div className="mb-6 flex items-center">
          <div className="flex items-center justify-start">
            <label className="block w-20 tracking-wide text-gray-500 text-xs font-bold mb-2">
              Artist Name
            </label>
          </div>
          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={profile?.artist_name ?? ""}
            onChange={(e) => handleForm("artist_name", e.target.value)}
            placeholder="Enter Artist Name..."
          />
        </div>

        <div className="mb-6 flex items-center">
          <div className="flex items-center justify-start">
            <label className="block w-20 tracking-wide text-gray-500 text-xs font-bold mb-2">
              Spotify
            </label>
          </div>

          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={profile?.artist_spotfy_id ?? ""}
            onChange={(e) => handleForm("artist_spotfy_id", e.target.value)}
            placeholder="Enter Spotify ID..."
          />
        </div>
        <div className="mb-6 flex items-center">
          <div className="flex items-center justify-start">
            <label className="block w-20 tracking-wide text-gray-500 text-xs font-bold mb-2">
              Apple Music ID
            </label>
          </div>
          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={profile?.artist_aplmus_id ?? ""}
            onChange={(e) => handleForm("artist_aplmus_id", e.target.value)}
            placeholder="Enter Apple ID..."
          />
        </div>
        <div className="w-full mb-6 flex items-center">
          <div className="flex items-center justify-start">
            <label className="block w-20 tracking-wide text-gray-500 text-xs font-bold mb-2">
              Publish
            </label>
            <input
              className={
                editableFieldsStatus?.published
                  ? "mr-2 mt-[-0.7rem] ml-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-blue-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[0] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-blue-500 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[0] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[0] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
                  : "mr-2 mt-[-0.7rem] ml-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[0] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary checked:after:absolute checked:after:z-[0] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[0] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] dark:bg-neutral-600 dark:after:bg-neutral-400 dark:checked:bg-primary dark:checked:after:bg-primary dark:focus:before:shadow-[3px_-1px_0px_13px_rgba(255,255,255,0.4)] dark:checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca]"
              }
              type="checkbox"
              role="switch"
              defaultChecked={editableFieldsStatus?.published}
              onClick={(e) => handleFieldsStatus("published", e.target.checked)}
            />
          </div>
          <input
            className="invisible appearance-none mt-2 text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
          />
        </div>
      </form>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default CreateArtistForm;
