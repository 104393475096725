import React, { useState } from "react";
import { EditFanProfileById } from "../../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import { logSuccess } from "../../rtsvrf-actions/Logging";

function EditFanProfile({
  user,
  userIpData,
  fan,
  closeModal,
  reload,
  setReload,
}) {
  const [loading, setLoading] = useState();
  const [profile, setProfile] = useState({
    ...fan,
  });

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Edit fan profile",
      action: "click",
      label: "Edit fan profile",
    });

    const result = await EditFanProfileById(fan.rtsvrf_fan_uuid, {
      ...profile,
      [`fan_${
        profile.fan_account_active ? "activated" : "deactivated"
      }_timestamp`]: new Date(),
    });
    setLoading(false);
    if (result?.error) {
      toast.error("Failed to update profile! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(`Fan Profile Updated - ${fan.fan_email}`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: `Profile active status changed from ${fan.fan_account_active} to ${profile.fan_account_active} for ${fan.fan_name}`,
        type: "Admin",
      });
      setReload(!reload);
      closeModal();
    }
  };

  return (
    <>
      {profile ? (
        <>
          <h3 className="text-center text-lg my-2 font-bold text-black">
            Edit fan profile
          </h3>
          <form class="w-full max-w-lg rounded-lg p-2">
            <div class="w-full mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Full Name
              </label>
              <input
                class="appearance-none text-xs block w-full font-xs bg-gray-300 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                disabled
                defaultValue={profile.fan_name}
                placeholder="John Doe"
                required
              />
            </div>

            <div class="w-full mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Email
              </label>
              <input
                class="appearance-none text-xs block w-full font-xs bg-gray-300 text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                disabled
                defaultValue={profile.fan_email}
                placeholder="Email"
                required
              />
            </div>
            <div className="mb-1 w-full">
              <div className="w-full mb-1 flex items-center">
                <input
                  id="web_search"
                  type="checkbox"
                  checked={profile?.fan_account_active ?? false}
                  onClick={() =>
                    handleForm(
                      "fan_account_active",
                      !profile?.fan_account_active
                    )
                  }
                  class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                />
                <label
                  for="web_search"
                  className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
                >
                  Fan Account Active
                </label>
              </div>
            </div>

            <div className="flex justify-center">
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                class="w-40 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
}

export default EditFanProfile;
