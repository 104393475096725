import React from "react";
import SuprSendInbox from "@suprsend/react-inbox";
import Bell from "../rtsvrf-assets/icons/bell.png";

const SentNotifications = ({ userId, subscriberId }) => {
  return (
    <div className="h-full w-56 flex justify-center items-center">
      {userId && subscriberId && (
        <SuprSendInbox
          theme={{
            bell: { color: "white" },
          }}
          bellComponent={() => (
            <button className="bg-white whitespace-nowrap flex items-center hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow">
              <img src={Bell} alt="bell" width={"24"} height={"24"} />
              View Sent Messages
            </button>
          )}
          workspaceKey={process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY}
          subscriberId={subscriberId}
          distinctId={userId}
        />
      )}
    </div>
  );
};

export default SentNotifications;
