import React from "react";
import Loading from "../Loading";

const StatisticsTable = ({ data, loading }) => {
  if (!data || loading) {
    return <Loading />;
  }
  if (data.length === 0) {
    return (
      <h3 className="text-center border border-solid rounded-md text-lg py-6 font-bold text-white">
        No Data Found
      </h3>
    );
  }

  return (
    <>
      <div class="w-full bg-gray-50 p-1 rounded-md relative overflow-x-auto shadow-md sm:rounded-lg">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" class="px-6 py-3">
                Label
              </th>
              <th scope="col" class="px-6 py-3">
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {data?.map((row) => {
              return (
                <>
                  <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <th
                      scope="row"
                      class="flex items-center px-6 py-4 text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      <div class="pl-3">
                        <div class="text-base font-semibold">{row.label}</div>
                      </div>
                    </th>
                    <td class="px-6 py-4"> {row.data ?? "-"}</td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StatisticsTable;
