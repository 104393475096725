export const canManageArtist = (user) => {
  if (!user) return false;
  return user.role_uberadmin || user.role_makeartist;
};

export const canManageArtistEditor = (user) => {
  if (!user) return false;
  return user.role_uberadmin || user.role_makeeditor;
};
export const canManageFans = (user) => {
  if (!user) return false;
  return user.role_uberadmin || user.role_managefans;
};

export const canManageGroups = (user) => {
  if (!user) return false;
  return user.role_uberadmin || user.role_groupslink;
};
export const canManageClaims = (user) => {
  if (!user) return false;
  return user.role_uberadmin || user.role_claimverify;
};
export const canManageStats = (user) => {
  if (!user) return false;
  return user.role_analytics;
};
