import React, { useEffect, useState } from "react";
import ArtistEditorTable from "./shared/ArtistEditorTable";
import {
  getAllArtistEditorList,
  getArtistEditorList,
  getArtistEditorMappingList,
} from "../rtsvrf-actions/Actions";
import Pagination from "./Pagination";

function ListArtistEditor({
  user,
  userIpData,
  countries,
  reload,
  artistEditorFilters,
  currentPage,
  setCurrentPage,
  handleEditRelations,
  handleEditUser,
}) {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [artistEditors, setArtistEditors] = useState([]);
  const [artistEditorMappings, setArtistEditorMappings] = useState([]);
  const listPerPage = 5;

  useEffect(() => {
    async function fetchPaginationData() {
      if (!user) return;
      setLoading(true);
      const numberOfRecords = await getAllArtistEditorList(artistEditorFilters);
      setTotal(numberOfRecords);
      const editors = await getArtistEditorList(
        currentPage,
        listPerPage,
        artistEditorFilters
      );
      const supabaseIds = editors.map(
        (editor) => editor.supabase_user_reference
      );

      if (editors) setArtistEditors(editors);
      const editorDetails = await getArtistEditorMappingList(supabaseIds);
      console.log(editorDetails, "editorDetails");
      if (editorDetails) setArtistEditorMappings(editorDetails);
      setLoading(false);
    }
    fetchPaginationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, user, reload, artistEditorFilters]);

  return (
    <div className="w-full my-2 ">
      <ArtistEditorTable
        loading={loading}
        data={artistEditors}
        countries={countries}
        handleEditRelations={handleEditRelations}
        handleEditUser={handleEditUser}
        artistEditorMappings={artistEditorMappings}
      />
      {artistEditors?.length ? (
        <div className="py-2 flex items-center justify-center">
          <Pagination
            listPerPage={listPerPage}
            total={total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ListArtistEditor;
