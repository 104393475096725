import React from "react";

const FanFilters = ({ fanFilters, setCurrentPage, setFanFilters }) => {
  const handleFilters = (field, operator, value) => {
    setTimeout(() => {
      let list = [...fanFilters];
      console.log(list, "list");
      // Remove old value
      list = list.filter((e) => e.field !== field);
      // Add new value
      list.push({ field, operator, value });
      setFanFilters(list);
      setCurrentPage(0);
    }, 500);
  };

  return (
    <div className="sm:flex sm: flex-col sm: items-center sm: justify-center">
      <div className="relative inline-block	my-1 mr-2">
        <label className="block text-sm font-medium text-white mb-1">
          Search Fan:
        </label>
        <input
          placeholder="Enter fan name or email..."
          className="bg-white rounded-md p-[5px] w-48 text-left text-sm cursor-pointer border-solid border border-black"
          onChange={(e) => {
            handleFilters("fan_name", "ilike", e.target.value);
            handleFilters("fan_email", "ilike", e.target.value);
          }}
        />
      </div>
      <div className="relative inline-block	my-1 mr-2">
        <label className="block text-sm font-medium text-white mb-1">
          Status:
        </label>
        <select
          onChange={(e) =>
            handleFilters("fan_account_active", "eq", e.target.value)
          }
          className="bg-white rounded-md p-[5px] w-48 text-left text-sm cursor-pointer border-solid border border-black"
        >
          <option value={""}>All</option>
          <option value={true}>Active</option>
          <option value={false}>Inactive</option>
        </select>
      </div>
    </div>
  );
};

export default FanFilters;
