import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import {
  bulkUpdateArtistData,
  fetchLabelCodes,
  getDefaultArtistData,
} from "../rtsvrf-actions/Actions";

import SelectArtist from "./shared/SelectArtist";

function LinkLabelForm({ user, userIpData }) {
  const [loading, setLoading] = useState();

  const [label, setLabel] = useState();
  const [selectedArtists, setSelectedArtists] = useState([]);

  const [defaultArtists, setDefaultArtists] = useState([]);
  const [defaultLabels, setDefaultLabels] = useState([]);

  useState(() => {
    const fetchDefaultData = async () => {
      const list = await getDefaultArtistData(6);
      setDefaultArtists(list);
      const labels = await fetchLabelCodes();
      setDefaultLabels(labels);
    };
    fetchDefaultData();
  }, []);

  const selectDelegationArtist = (selectedOption, name) => {
    console.log(selectedOption, "option", name);
    setSelectedArtists(selectedOption);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (!label || !selectedArtists.length) {
      toast.error("Select artists and label both!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    ReactGA.event({
      category: "Link artist with group/label",
      action: "click",
      label: "Link artist with group/label",
    });

    console.log(selectedArtists, label);
    const list = selectedArtists?.map((artist) => {
      let labels = [];
      if (artist?.artist_label_connection?.length) {
        if (!artist.artist_label_connection.includes(label))
          labels = [...artist.artist_label_connection, label];
        else labels = artist.artist_label_connection;
      } else {
        labels = [label];
      }
      return {
        rtsvrf_artist_id: artist.rtsvrf_artist_id,
        data: { artist_label_connection: labels },
      };
    });

    const result = await bulkUpdateArtistData(list);

    setLoading(false);
    if (result?.error) {
      toast.error("Failed to link Group/Label! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Artists linked successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setSelectedArtists([]);
      setLabel();
      await logSuccess(`Artists linked with ${label}`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: `Artists updated with ${label} - ${selectedArtists
          .map((artist) => artist.artist_name)
          .join(",")}`,
        type: "Admin",
      });
    }
  };

  return (
    <div className="my-2 lg:ml-0">
      <h3 className="text-center text-white text-xl my-2 font-bold">
        Edit Artist Group Relationship
      </h3>
      <form class="w-full max-w-lg bg-gray-200 shadow-xl rounded-lg p-8">
        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Artists
          </label>
          <SelectArtist
            artistData={defaultArtists}
            name={"fandata_delegated_artists"}
            isMulti={true}
            internalSearchOnly={false}
            selectedArtist={selectedArtists || []}
            handleArtistSelection={selectDelegationArtist}
          />
        </div>

        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Label Group Code
          </label>
          <select
            onChange={(e) => setLabel(e.target.value)}
            value={label ?? ""}
            class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value={""}>Select a label</option>
            {defaultLabels?.map((defaultabel) => (
              <option value={defaultabel?.rtsvrf_label_code}>
                {defaultabel?.rtsvrf_label_name}
              </option>
            ))}
          </select>
        </div>
      </form>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
        >
          {loading ? "Linking..." : "Link"}
        </button>
      </div>
    </div>
  );
}

export default LinkLabelForm;
