export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validURL = (url) => {
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  console.log(urlPattern.test(url), "test valid url");
  return urlPattern.test(url);
};

export const hideSensitiveInfo = (str) => {
  if (str) {
    return "X".repeat(str.length) + str.substring(str.length);
  }
  return str;
};

export const isArrayEqual = (array1, array2) => {
  if (array1?.length !== array2?.length) return false;

  for (let i = 0; i < array1?.length; i++) {
    if (!array2?.includes(array1?.[i])) return false;
  }

  return true;
};

export const formatFollowersCount = (count) => {
  if (!count) return "N/A";
  if (typeof count !== "number") return count;
  if (count < 1000) {
    return count;
  } else if (count < 1000000) {
    return (count / 1000).toFixed(0) + "K";
  } else if (count < 1000000000) {
    return (count / 1000000).toFixed(0) + "M";
  } else {
    return (count / 1000000000).toFixed(0) + "B";
  }
};
