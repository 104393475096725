import React, { useState } from "react";
import ReactGA from "react-ga4";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import { toast } from "react-toastify";
import CloseIcon from "../rtsvrf-assets/icons/close.png";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { customStyles } from "../rtsvrf-utils/Constants";
import suprsend from "@suprsend/web-sdk";
import {
  getAvAdminMappingsById,
  getUserSubscriberKey,
} from "../rtsvrf-actions/Actions";

Modal.setAppElement("#root");

function SignIn({ userIpData }) {
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const [otp, setOtp] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  let navigate = useNavigate();

  const handleForm = (value) => {
    setEmail(value);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Sign In",
      action: "click",
      label: "Sign In",
    });
    const { data, error } = await supabase.auth.signInWithOtp({
      email: email,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_REDIRECT_URL}`,
        shouldCreateUser: false,
      },
    });
    if (error) {
      console.log(error);
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      await logSuccess("Token Requested", {
        ...userIpData,
        email: email,
        timestamp: new Date(),
        type: "Admin",
      });
      toast.success("OTP sent to email!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      openModal();
    }
    console.log(data, error);
    setLoading(false);
  };

  const verifyEmail = async () => {
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Verify OTP",
      action: "click",
      label: "Verify OTP",
    });
    const { data, error } = await supabase.auth.verifyOtp({
      email,
      token: otp,
      type: "email",
    });
    if (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (data) {
      const isValidUser = await getAvAdminMappingsById(data.user.id);
      const validUserData = isValidUser?.data;
      console.log(validUserData, "validUserData");
      if (validUserData) {
        await logSuccess("Log In", {
          ...userIpData,
          email: data.user.email,
          timestamp: new Date(),
          type: "Admin",
        });
        console.log(data.user);
        suprsend.identify(data.user.id);
        suprsend.user.set("name", data.user.user_metadata?.name);
        suprsend.user.add_email(data.user.email);
        if (!data.user.user_metadata?.suprsend_subscriber_id) {
          await getUserSubscriberKey(data.user);
        }
        navigate(`/admin/${data.user.id}`);
      } else {
        toast.error("Permission denied to access the App", {
          position: toast.POSITION.TOP_RIGHT,
        });
        await logSuccess("Invalid User Log In", {
          ...userIpData,
          email: data.user.email,
          timestamp: new Date(),
          details: `User with email -${data.user.email} and ID - ${data.user.id} tried to access AV Admin App without permission`,
          type: "Admin",
        });
        await supabase.auth.signOut();
        localStorage.clear();
      }
      closeModal();
    }

    setLoading(false);
  };

  return (
    <div className="flex flex-col justify-center items-center pt-10 pb-40">
      <h3 className="text-center text-lg my-2 font-bold text-white">Sign In</h3>
      <form class="w-full max-w-lg bg-white border solid shadow-xl rounded-lg p-4">
        <div class="w-full mb-6">
          <label class="block uppercase tracking-wide text-black text-xs font-bold mb-2">
            Email
          </label>
          <input
            class="appearance-none text-xs block w-full font-xs bg-white  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            defaultValue={email}
            onChange={(e) => handleForm(e.target.value)}
            placeholder="Enter your email..."
            required
          />
        </div>

        <div className="flex justify-center">
          <button
            type="submit"
            onClick={(e) => handleSubmit(e)}
            class="w-40 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
          >
            {loading ? "Sending email..." : "Log In"}
          </button>
        </div>
      </form>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="SignIn Modal"
      >
        <div className="flex justify-between">
          <h2 className="text-black text-lg">Enter your login code</h2>
          <span onClick={closeModal} className="cursor-pointer">
            <img src={CloseIcon} alt="close" width={"15"} height={"15"} />
          </span>
        </div>
        <p className="my-4 text-sm text-gray-500">
          Please enter the one time code that was sent to your email to complete
          the login process
        </p>
        <div className="flex flex-col p-4 text-black items-center">
          <div class="w-full mb-6">
            <label class="block uppercase tracking-wide text-black text-xs font-bold mb-2">
              OTP:
            </label>
            <input
              class="appearance-none text-xs block w-full font-xs bg-white  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
              type="number"
              defaultValue={otp}
              onChange={(e) => setOtp(e.target.value)}
              placeholder="Enter OTP here..."
              required
            />
          </div>

          <button
            onClick={() => verifyEmail()}
            className="text-white bg-blue-700 hover:bg-blue-800 rounded-lg text-sm px-6 py-3"
          >
            {loading ? "Verifying..." : "Verify"}
          </button>
        </div>
      </Modal>
    </div>
  );
}

export default SignIn;
