import React, { useState } from "react";

import Select from "react-select";
import { getArtistsByNameSearch } from "../../rtsvrf-actions/Actions";

function SelectArtist({
  artistData,
  name,
  handleArtistSelection,
  selectedArtist,
  internalSearchOnly,
  isMulti,
}) {
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState();
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      outerWidth: "380px",
      innerWidth: "380px",
      minWidth: "380px",
      maxWidth: "380px",
      innerHeight: "250px",
      outerHeight: "250px",
      maxHeight: "150px",
      overflow: "scroll",
    }),
  };
  const handleSearch = (inputValue) => {
    setLoading(true);
    if (internalSearchOnly) {
      const filteredArtists = artistData?.filter((artist) =>
        artist?.artist_name?.toLowerCase()?.includes(inputValue?.toLowerCase())
      );
      setSearchResults(filteredArtists);
      setLoading(false);
    } else {
      getArtistsByNameSearch(inputValue).then((res) => {
        if (res) setSearchResults(res);
        setLoading(false);
      });
    }
  };

  return (
    <>
      <Select
        isMulti={isMulti ?? false}
        options={searchResults?.length > 0 ? searchResults : artistData}
        styles={customStyles}
        getOptionLabel={(option) => option?.artist_name}
        isLoading={loading}
        getOptionValue={(option) => option?.rtsvrf_artist_id}
        placeholder="Search for an artist..."
        onInputChange={(inputValue) => handleSearch(inputValue)}
        onChange={(selectedOption) =>
          handleArtistSelection(selectedOption, name)
        }
        required={false}
        value={selectedArtist || null}
        isSearchable
      />
    </>
  );
}

export default SelectArtist;
