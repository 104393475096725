import React, { useState, useEffect } from "react";

import { getAvAdminMappingsById, getUserAuth } from "../rtsvrf-actions/Actions";

import Loading from "../rtsvrf-components/Loading";
import Error from "../rtsvrf-components/Error";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams } from "react-router-dom";

import EditArtistProfile from "../rtsvrf-components/EditArtistProfile";
import CreateArtistForm from "../rtsvrf-components/CreateArtistForm";
import {
  canManageArtist,
  canManageClaims,
} from "../rtsvrf-utils/AccessControls";
import VerifyArtist from "../rtsvrf-components/VerifyArtist";

function ClaimVerification({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [user, setUser] = useState();
  const [artistEditor, setArtistEditor] = useState();

  const { id } = useParams();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!canManageClaims(artistEditor)) {
      setError("You do not have enough access to view this page");
    } else {
      setError();
    }
  }, [artistEditor]);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);

    const result = await getAvAdminMappingsById(id);
    console.log(result);
    const userData = result.data;
    console.log(userData, "userData");
    if (userData) {
      setArtistEditor(userData);
    }

    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error title={"Access Denied"} subtext={error} />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          userIpData={userIpData}
          links={[
            { label: "Home", link: `/admin/${user?.id}` },
            { label: "AV Admin", link: null },
            { label: "Manage Claims", link: null },
          ]}
        />
      )}
      <div className="flex lg:flex-col justify-around lg:items-center">
        {user ? <VerifyArtist user={user} userIpData={userIpData} /> : null}
      </div>
    </div>
  );
}

export default ClaimVerification;
