import React, { useEffect, useState } from "react";
import FansTable from "./shared/FansTable";
import { getAllFansList, getFansList } from "../rtsvrf-actions/Actions";
import Pagination from "./Pagination";

function ListFans({
  user,
  userIpData,
  countries,
  fanFilters,
  reload,
  handleEditUser,
  currentPage,
  setCurrentPage,
}) {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const [fans, setFans] = useState([]);
  const listPerPage = 10;

  useEffect(() => {
    async function fetchPaginationData() {
      if (!user) return;
      setLoading(true);
      const numberOfRecords = await getAllFansList(fanFilters);
      setTotal(numberOfRecords);
      const fanList = await getFansList(currentPage, listPerPage, fanFilters);
      console.log(fanList, "Fans", fanFilters);
      if (fanList) setFans(fanList);
      setLoading(false);
    }
    fetchPaginationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, user, reload, fanFilters]);

  return (
    <div className="w-full my-2 lg:ml-0">
      <FansTable
        loading={loading}
        data={fans}
        countries={countries}
        handleEditUser={handleEditUser}
      />
      {fans?.length ? (
        <div className="py-2 flex items-center justify-center">
          <Pagination
            listPerPage={listPerPage}
            total={total}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      ) : null}
    </div>
  );
}

export default ListFans;
