import React, { useState, useEffect } from "react";

import { getAvAdminMappingsById, getUserAuth } from "../rtsvrf-actions/Actions";
import { supabase } from "../rtsvrf-database/SupabaseClient";
import Loading from "../rtsvrf-components/Loading";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams, useNavigate } from "react-router-dom";

import LandingSection from "../rtsvrf-components/LandingSection";

function Home({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [adminProfile, setAdminProfile] = useState();
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    console.log(userAuthData);
    setUser(userAuthData);

    const result = await getAvAdminMappingsById(id);
    const userData = result?.data;
    if (userData) {
      setAdminProfile(userData);
    } else {
      navigate(`/404`);
      await supabase.auth.signOut();
      localStorage.clear();
    }
    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          userIpData={userIpData}
          links={[
            { label: "Home", link: `/admin/${user.id}` },
            { label: "AV Admin Profile", link: null },
          ]}
        />
      )}
      <LandingSection
        userIpData={userIpData}
        user={user}
        adminProfile={adminProfile}
      />
    </div>
  );
}

export default Home;
