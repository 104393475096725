import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import { toast } from "react-toastify";
import { logSuccess } from "../../rtsvrf-actions/Logging";
import {
  createArtistEditor,
  fetchLabelCodes,
  getArtistsByRtsvrfId,
  getDefaultArtistData,
  updateArtistEditorMapping,
} from "../../rtsvrf-actions/Actions";
import SelectDropDown from "../Select";
import SelectArtist from "../shared/SelectArtist";
import { isArrayEqual } from "../../rtsvrf-utils/Helpers";

function EditArtistEditorForm({
  user,
  userIpData,
  artistEditor,
  closeModal,
  reload,
  setReload,
}) {
  const [loading, setLoading] = useState();
  const [email, setEmail] = useState();
  const [profile, setProfile] = useState(artistEditor);
  const [selectedFanDelegationArtist, setSelectedFanDelegationArtist] =
    useState([]);
  const [selectedEditorDelegationArtist, setSelectedEditorDelegationArtist] =
    useState([]);
  const [defaultArtists, setDefaultArtists] = useState([]);
  const [defaultLabels, setDefaultLabels] = useState([]);

  useState(() => {
    const fetchDefaultData = async () => {
      const list = await getDefaultArtistData(4);
      setDefaultArtists(list);
      const labels = await fetchLabelCodes();
      setDefaultLabels(labels);
    };
    fetchDefaultData();
  }, []);

  useState(() => {
    const fetchDelegationData = async () => {
      console.log(artistEditor, "artistEditor");
      if (!artistEditor) return;

      if (artistEditor?.fandata_delegated_artists?.length) {
        const list = await getArtistsByRtsvrfId(
          artistEditor.fandata_delegated_artists
        );
        setSelectedFanDelegationArtist(list);
      }
      if (artistEditor?.editor_delegated_artists?.length) {
        const list = await getArtistsByRtsvrfId(
          artistEditor.editor_delegated_artists
        );
        setSelectedEditorDelegationArtist(list);
      }
    };
    fetchDelegationData();
  }, [artistEditor]);

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const selectDelegationArtist = (selectedOption, name) => {
    const list = selectedOption?.map((artist) => artist.rtsvrf_artist_id);
    handleForm(name, list);
    console.log(selectedOption, "option", name);
    if (name === "fandata_delegated_artists") {
      setSelectedFanDelegationArtist(selectedOption);
    } else {
      setSelectedEditorDelegationArtist(selectedOption);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    ReactGA.event({
      category: "Update Artist Editor Profile",
      action: "click",
      label: "Update Artist Editor Profile",
    });

    const profileResult = await updateArtistEditorMapping(
      artistEditor.rtsvrf_artist_editor_id,
      {
        ...profile,
      }
    );

    setLoading(false);
    if (profileResult?.error) {
      toast.error("Failed to update artist editor! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      let profileDifference = Object.keys(profile).filter(
        (k) => profile[k] !== artistEditor[k] && !Array.isArray(profile[k])
      );

      const profileDetails =
        profileDifference?.map(
          (field) =>
            `${field} changed from ${artistEditor[field] || null} to ${
              profile[field] || null
            }`
        ) || [];

      toast.success("Artist editor updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      await logSuccess(`Artist Editor Updated - ${profile?.note}`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: `Supabase User Reference: ${
          profile?.supabase_user_reference
        }, Details: ${profileDetails},${
          !isArrayEqual(
            profile.fandata_delegated_artists,
            artistEditor.fandata_delegated_artists
          )
            ? `fandata_delegated_artists from ${artistEditor.fandata_delegated_artists} to ${profile.fandata_delegated_artists}, `
            : ""
        }${
          !isArrayEqual(
            profile.editor_delegated_artists,
            artistEditor.editor_delegated_artists
          )
            ? `editor_delegated_artists changed from ${artistEditor.editor_delegated_artists} to ${profile.editor_delegated_artists}`
            : ""
        }
        `,
        type: "Admin",
      });
      setReload(!reload);
      closeModal();
    }
  };

  return (
    <div className="my-2 lg:ml-0">
      <h3 className="text-center text-xl my-2 font-bold">Edit relationships</h3>
      <form class="w-full max-w-lg rounded-lg">
        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Fan Data Delegated Artists
          </label>
          <SelectArtist
            artistData={defaultArtists}
            name={"fandata_delegated_artists"}
            isMulti={true}
            internalSearchOnly={false}
            selectedArtist={selectedFanDelegationArtist || []}
            handleArtistSelection={selectDelegationArtist}
          />
        </div>
        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Editor Delegated Artists
          </label>
          <SelectArtist
            artistData={defaultArtists}
            name={"editor_delegated_artists"}
            isMulti={true}
            internalSearchOnly={false}
            selectedArtist={selectedEditorDelegationArtist || []}
            handleArtistSelection={selectDelegationArtist}
          />
        </div>

        <div class="w-full flex items-center my-3">
          <input
            id="is_label_group_manager"
            type="checkbox"
            checked={profile?.is_label_group_manager}
            onClick={() =>
              handleForm(
                "is_label_group_manager",
                !profile?.is_label_group_manager
              )
            }
            class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
          />
          <label
            for="is_label_group_manager"
            className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
          >
            Is Label Group Manager?
          </label>
        </div>
        <div class="w-full mb-3">
          <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
            Label Group Code
          </label>
          <select
            onChange={(e) => handleForm("label_group_code", e.target.value)}
            value={profile?.label_group_code}
            class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
          >
            <option value={""}>Select a label</option>
            {defaultLabels?.map((label) => (
              <option value={label?.rtsvrf_label_code}>
                {label?.rtsvrf_label_name}
              </option>
            ))}
          </select>
        </div>
      </form>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default EditArtistEditorForm;
