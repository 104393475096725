import React, { useState, useEffect } from "react";

import {
  getArtistEditorList,
  getAvAdminMappingsById,
  getCountryNames,
  getUserAuth,
} from "../rtsvrf-actions/Actions";

import Loading from "../rtsvrf-components/Loading";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import ListArtistEditor from "../rtsvrf-components/ListArtistEditor";
import { customStyles } from "../rtsvrf-utils/Constants";
import CreateArtistEditorForm from "../rtsvrf-components/modals/CreateArtistEditor";
import EditArtistEditorForm from "../rtsvrf-components/modals/EditArtistEditor";
import { canManageArtistEditor } from "../rtsvrf-utils/AccessControls";
import Error from "../rtsvrf-components/Error";
import EditArtistEditorProfile from "../rtsvrf-components/modals/EditArtistEditorProfile";
import ArtistEditorFilters from "../rtsvrf-components/filters/ArtistEditorFilters";
Modal.setAppElement("#root");

function ArtistEditor({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState();
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [user, setUser] = useState();
  const [updateModalOpen, setUpdateModalOpen] = useState(false);
  const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(false);
  const [editUser, setEditUser] = useState();
  const [editUserProfile, setEditUserProfile] = useState();
  const [countries, setCountries] = useState([]);
  const [artistEditorFilters, setArtistEditorFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [avAdmin, setAvAdmin] = useState();

  const { id } = useParams();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!canManageArtistEditor(avAdmin)) {
      setError("You do not have enough access to view this page");
    } else {
      setError();
    }
  }, [avAdmin]);

  useEffect(() => {
    const getCountries = async () => {
      const countryList = await getCountryNames();
      if (countryList?.length) {
        setCountries(countryList);
      }
    };
    getCountries();
  }, []);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);

    const result = await getAvAdminMappingsById(id);
    console.log(result);
    const userData = result.data;
    console.log(userData, "userData");
    if (userData) {
      setAvAdmin(userData);
    }

    setLoading(false);
  };
  const handleAddArtistEditor = () => {
    setCreateModalOpen(true);
  };
  const closeCreateModal = () => {
    setCreateModalOpen(false);
  };
  const handleEditRelations = (user, profile) => {
    console.log(user, profile, "111");
    setEditUser(user);
    setEditUserProfile(profile);
    setUpdateModalOpen(true);
  };
  const handleEditUser = (user, profile) => {
    console.log(user, profile, "222");
    setEditUser(user);
    setEditUserProfile(profile);
    setUpdateProfileModalOpen(true);
  };

  const closeUpdateModal = () => {
    setUpdateModalOpen(false);
  };
  const closeUpdateProfileModal = () => {
    setUpdateProfileModalOpen(false);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error title={"Access Denied"} subtext={error} />;
  }
  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          userIpData={userIpData}
          buttons={[
            { title: "Add Artist Editor", onClick: handleAddArtistEditor },
          ]}
          links={[
            { label: "Home", link: `/admin/${user?.id}` },
            { label: "AV Admin", link: null },
            { label: "Add/Modify Artist Editor", link: null },
          ]}
        />
      )}

      <Modal
        isOpen={createModalOpen}
        onRequestClose={closeCreateModal}
        style={customStyles}
        contentLabel="Message"
      >
        <CreateArtistEditorForm
          user={user}
          userIpData={userIpData}
          countries={countries}
          reload={reload}
          setReload={setReload}
          closeModal={closeCreateModal}
        />
      </Modal>

      <div className="flex flex-col justify-around ml-5 lg:ml-0">
        {user ? (
          <>
            <ArtistEditorFilters
              artistEditorFilters={artistEditorFilters}
              setArtistEditorFilters={setArtistEditorFilters}
              setCurrentPage={setCurrentPage}
            />

            <ListArtistEditor
              user={user}
              artistEditorFilters={artistEditorFilters}
              userIpData={userIpData}
              countries={countries}
              reload={reload}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleEditRelations={handleEditRelations}
              handleEditUser={handleEditUser}
            />
          </>
        ) : null}
      </div>

      <Modal
        isOpen={updateModalOpen}
        onRequestClose={closeUpdateModal}
        style={customStyles}
        contentLabel="Message"
      >
        <EditArtistEditorForm
          user={user}
          artistEditor={editUserProfile}
          userIpData={userIpData}
          reload={reload}
          setReload={setReload}
          closeModal={closeUpdateModal}
        />
      </Modal>
      <Modal
        isOpen={updateProfileModalOpen}
        onRequestClose={closeUpdateProfileModal}
        style={customStyles}
        contentLabel="Message"
      >
        <EditArtistEditorProfile
          user={user}
          countries={countries}
          artistEditor={editUser}
          artistEditorProfile={editUserProfile}
          userIpData={userIpData}
          reload={reload}
          setReload={setReload}
          closeModal={closeUpdateProfileModal}
        />
      </Modal>
    </div>
  );
}

export default ArtistEditor;
