import { Logtail } from "@logtail/browser";
import { insertLogHistory } from "./Actions";
const logtail = new Logtail(process.env.REACT_APP_BETTER_STACK_ID);

export const logError = (message) => {
  if (!message) return;
  logtail.error(message);
};

export const logSuccess = async (message, data = {}) => {
  if (!message) return;
  console.log(message, data);
  logtail.info(message, data);

  await insertLogHistory({ ...data, message });
};
