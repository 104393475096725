import React, { useState } from "react";
import {
  editArtistEditorProfile,
  updateArtistEditorMapping,
  updateArtistEditorProfile,
} from "../../rtsvrf-actions/Actions";
import ReactGA from "react-ga4";
import moment from "moment";
import { toast } from "react-toastify";
import { logSuccess } from "../../rtsvrf-actions/Logging";

function EditArtistEditorProfile({
  user,
  userIpData,
  artistEditor,
  closeModal,
  artistEditorProfile,
  reload,
  setReload,
  countries,
}) {
  const [loading, setLoading] = useState();
  const [profile, setProfile] = useState({
    ...artistEditor?.user_metadata,
    updated_at: artistEditor?.updated_at,
    created_at: artistEditor?.created_at,
  });
  const [profileDetails, setProfileDetails] = useState(artistEditorProfile);

  const handleForm = (name, value) => {
    setProfile({ ...profile, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    ReactGA.event({
      category: "Edit aritst editor profile",
      action: "click",
      label: "Edit aritst editor profile",
    });
    const difference = Object.keys(profile).filter(
      (k) => profile[k] !== artistEditor?.user_metadata[k]
    );
    const details =
      difference?.map(
        (field) =>
          `${field} changed from ${artistEditor?.user_metadata[field]} to ${profile[field]}`
      ) || [];
    console.log(profile, "profile");

    const result = await editArtistEditorProfile(artistEditor.id, profile);
    if (
      profileDetails.editor_account_active !==
      artistEditorProfile.editor_account_active
    ) {
      console.log(artistEditorProfile, profileDetails);
      const profileResult = await updateArtistEditorMapping(
        artistEditorProfile.rtsvrf_artist_editor_id,
        {
          ...profileDetails,
          ...(profileDetails?.editor_account_active !==
          artistEditor?.editor_account_active
            ? {
                [`editor_${
                  profileDetails?.editor_account_active
                    ? "activated"
                    : "deactivated"
                }_timestamp`]: new Date(),
              }
            : {}),
        }
      );
      if (!profileResult?.error)
        details.push(
          `editor_account_active changed from ${artistEditorProfile.editor_account_active} to ${profileDetails.editor_account_active}`
        );
    }
    setLoading(false);
    if (result.error) {
      toast.error("Failed to update profile! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.success("Updated successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(`Editor Profile Updated - ${artistEditor.email}`, {
        ...userIpData,
        email: user.email,
        timestamp: new Date(),
        details: details.join(","),
        type: "Admin",
      });
      setReload(!reload);
      closeModal();
    }
  };

  return (
    <>
      {profile ? (
        <>
          <h3 className="text-center text-lg my-2 font-bold text-black">
            Edit user profile
          </h3>
          <form class="w-full max-w-lg rounded-lg p-2">
            <div class="w-full mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Full Name
              </label>
              <input
                class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                defaultValue={profile.name}
                onChange={(e) => handleForm("name", e.target.value)}
                placeholder="John Doe"
                required
              />
            </div>

            <div class="w-full mb-3">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Company
              </label>
              <input
                class="appearance-none text-xs block w-full font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                type="text"
                defaultValue={profile.company}
                onChange={(e) => handleForm("company", e.target.value)}
                placeholder="Company"
                required
              />
            </div>
            <div class="flex flex-wrap -mx-3 mb-1">
              <div class="w-1/2 md:w-full px-3 mb-3">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  City
                </label>
                <input
                  defaultValue={profile.city}
                  onChange={(e) => handleForm("city", e.target.value)}
                  class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  type="text"
                  placeholder="Albuquerque"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-3">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  Country
                </label>
                <div class="relative">
                  {countries.length ? (
                    <select
                      onChange={(e) => handleForm("country", e.target.value)}
                      defaultValue={profile.country}
                      class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    >
                      {countries?.map((country) => (
                        <option value={country.iso2}>{country.name}</option>
                      ))}
                    </select>
                  ) : null}
                </div>
              </div>
            </div>

            <div class="w-full  mb-3 ">
              <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                Phone Number
              </label>
              <input
                onChange={(e) => handleForm("phone_number", e.target.value)}
                class="appearance-none text-xs block w-full bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                type="text"
                defaultValue={profile.phone_number}
                placeholder="#123 456 789"
              />
            </div>

            <div className="w-full my-3 flex items-center">
              <input
                id="account_active"
                type="checkbox"
                checked={profileDetails?.editor_account_active ?? false}
                onClick={() =>
                  setProfileDetails({
                    ...profileDetails,
                    editor_account_active:
                      !profileDetails?.editor_account_active,
                  })
                }
                class="w-4 h-4 -mt-2 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
              />
              <label
                for="account_active"
                className="block tracking-wide text-gray-500 text-xs font-bold mb-2"
              >
                Editor Account Active
              </label>
            </div>

            <div class="flex flex-wrap -mx-3 mb-1">
              <div class="w-1/2 md:w-full px-3 mb-3 ">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  Joined On
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    profile.created_at
                      ? moment(profile.created_at).format("DD MMMM YYYY")
                      : ""
                  }
                  placeholder="Date"
                />
              </div>

              <div class="w-1/2 md:w-full px-3 mb-3 ">
                <label class="block  tracking-wide text-gray-500 text-xs font-bold mb-1">
                  Last Updated At
                </label>
                <input
                  class="appearance-none text-xs block w-full bg-gray-300 text-gray-500 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  disabled={true}
                  defaultValue={
                    profile.updated_at
                      ? moment(profile.updated_at).format("LLL")
                      : ""
                  }
                  placeholder="Date"
                />
              </div>
            </div>

            <div className="flex justify-center">
              <button
                type="button"
                onClick={(e) => handleSubmit(e)}
                class="w-40 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
              >
                {loading ? "Saving..." : "Save"}
              </button>
            </div>
          </form>
        </>
      ) : null}
    </>
  );
}

export default EditArtistEditorProfile;
