import React, { useState, useEffect } from "react";

import { getAvAdminMappingsById, getUserAuth } from "../rtsvrf-actions/Actions";

import Loading from "../rtsvrf-components/Loading";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams } from "react-router-dom";

import CreateLabelForm from "../rtsvrf-components/CreateLabelForm";
import LinkLabelForm from "../rtsvrf-components/LinkLabelForm";
import Error from "../rtsvrf-components/Error";
import { canManageGroups } from "../rtsvrf-utils/AccessControls";

function CreateLabel({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [error, setError] = useState();
  const [artistEditor, setArtistEditor] = useState();

  const { id } = useParams();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  useEffect(() => {
    if (!canManageGroups(artistEditor)) {
      setError("You do not have enough access to view this page");
    } else {
      setError();
    }
  }, [artistEditor]);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);

    const result = await getAvAdminMappingsById(id);
    console.log(result);
    const userData = result.data;
    console.log(userData, "userData");
    if (userData) {
      setArtistEditor(userData);
    }

    setLoading(false);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error title={"Access Denied"} subtext={error} />;
  }

  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          userIpData={userIpData}
          links={[
            { label: "Home", link: `/admin/${user?.id}` },
            { label: "AV Admin", link: null },
            { label: "Create/Link Group", link: null },
          ]}
        />
      )}

      <div className="flex md:flex-col justify-around md:justify-center md:items-center items-baseline">
        <CreateLabelForm user={user} userIpData={userIpData} />
        <LinkLabelForm user={user} userIpData={userIpData} />
      </div>
    </div>
  );
}

export default CreateLabel;
