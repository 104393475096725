import React, { useState } from "react";

function Card({ handleClick, title, icon }) {
  return (
    <div
      onClick={handleClick}
      class="w-96 flex flex-col justify-center items-center p-6 my-4 mx-1 bg-white border border-gray-200 rounded-lg shadow cursor-pointer"
    >
      <img
        class="w-10 h-10 text-gray-500 dark:text-gray-400 mb-3"
        src={icon}
        alt={title}
      />

      <h5 class="mb-2 text-xl font-semibold tracking-tight text-gray-900 dark:text-white">
        {title}
      </h5>
    </div>
  );
}

export default Card;
