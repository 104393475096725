import React, { useState, useEffect } from "react";

import {
  getAvAdminMappingsById,
  getCountryNames,
  getUserAuth,
} from "../rtsvrf-actions/Actions";

import Loading from "../rtsvrf-components/Loading";
import Navbar from "../rtsvrf-components/Navbar";
import { useParams } from "react-router-dom";
import Modal from "react-modal";
import ListFans from "../rtsvrf-components/ListFans";
import { customStyles } from "../rtsvrf-utils/Constants";
import { canManageFans } from "../rtsvrf-utils/AccessControls";
import Error from "../rtsvrf-components/Error";
import EditArtistEditorProfile from "../rtsvrf-components/modals/EditArtistEditorProfile";
import EditFanProfile from "../rtsvrf-components/modals/EditFanProfile";
import FanFilters from "../rtsvrf-components/filters/FanFilters";
Modal.setAppElement("#root");

function ManageFans({ userIpData }) {
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [error, setError] = useState();
  const [user, setUser] = useState();
  const [updateProfileModalOpen, setUpdateProfileModalOpen] = useState(false);
  const [editUser, setEditUser] = useState();
  const [countries, setCountries] = useState([]);
  const [fanFilters, setFanFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [avAdmin, setAvAdmin] = useState();

  const { id } = useParams();

  useEffect(() => {
    reloadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!canManageFans(avAdmin)) {
      setError("You do not have enough access to view this page");
    } else {
      setError();
    }
  }, [avAdmin]);

  useEffect(() => {
    const getCountries = async () => {
      const countryList = await getCountryNames();
      if (countryList?.length) {
        setCountries(countryList);
      }
    };
    getCountries();
  }, []);

  const reloadData = async () => {
    if (loading) return;
    setLoading(true);
    const userAuthData = await getUserAuth();
    setUser(userAuthData);

    const result = await getAvAdminMappingsById(id);
    console.log(result);
    const userData = result.data;
    console.log(userData, "userData");
    if (userData) {
      setAvAdmin(userData);
    }

    setLoading(false);
  };

  const handleEditUser = (data) => {
    setEditUser(data);
    setUpdateProfileModalOpen(true);
  };
  const closeUpdateProfileModal = () => {
    setUpdateProfileModalOpen(false);
  };

  if (loading) {
    return <Loading />;
  }
  if (error) {
    return <Error title={"Access Denied"} subtext={error} />;
  }
  return (
    <div
      className={
        "bg-gradient-to-r from-gray-400 to-gray-600 sm:flex sm:flex-col px-4 "
      }
    >
      {user && (
        <Navbar
          user={user}
          userIpData={userIpData}
          links={[
            { label: "Home", link: `/admin/${user?.id}` },
            { label: "AV Admin", link: null },
            { label: "Manage Fans", link: null },
          ]}
        />
      )}

      <div className="flex flex-col  ml-5 sm:ml-1 justify-around ">
        {user ? (
          <>
            <FanFilters
              fanFilters={fanFilters}
              setFanFilters={setFanFilters}
              setCurrentPage={setCurrentPage}
            />
            <ListFans
              user={user}
              userIpData={userIpData}
              countries={countries}
              fanFilters={fanFilters}
              reload={reload}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              handleEditUser={handleEditUser}
            />
          </>
        ) : null}
      </div>

      <Modal
        isOpen={updateProfileModalOpen}
        onRequestClose={closeUpdateProfileModal}
        style={customStyles}
        contentLabel="Message"
      >
        <EditFanProfile
          user={user}
          countries={countries}
          fan={editUser}
          userIpData={userIpData}
          reload={reload}
          setReload={setReload}
          closeModal={closeUpdateProfileModal}
        />
      </Modal>
    </div>
  );
}

export default ManageFans;
