import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";

import { toast } from "react-toastify";
import { logSuccess } from "../rtsvrf-actions/Logging";
import { createLabelGroup } from "../rtsvrf-actions/Actions";
import { validURL, validateEmail } from "../rtsvrf-utils/Helpers";

function CreateLabelForm({ user, userIpData }) {
  const [loading, setLoading] = useState();

  const [label, setLabel] = useState();

  const handleForm = (name, value) => {
    setLabel({ ...label, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;
    if (!label || !label.rtsvrf_label_name || !label.rtsvrf_label_code) {
      toast.error("Fill up Name and Code proeprly", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (label.group_link && !validURL(label.group_link)) {
      toast.error("Enter valid link", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    if (
      label.rtsvrf_label_contact_email &&
      !validateEmail(label.rtsvrf_label_contact_email)
    ) {
      toast.error("Enter valid email", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    setLoading(true);
    ReactGA.event({
      category: "Create Group/label",
      action: "click",
      label: "Create Group/label",
    });

    const labelResult = await createLabelGroup({
      ...label,
    });

    setLoading(false);
    if (labelResult?.error) {
      toast.error("Failed to add Group/Label! Try again.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setLabel();
      toast.success("Group/Label added successfully!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      await logSuccess(
        `New Group/Label ${label?.rtsvrf_label_name || ""} Added`,
        {
          ...userIpData,
          email: user.email,
          timestamp: new Date(),
          details: JSON.stringify(label),
          type: "Admin",
        }
      );
    }
  };

  return (
    <div className="my-2 ml-5 lg:ml-0">
      <h3 className="text-center text-xl my-2 font-bold text-white">
        Create/Link Label Group
      </h3>
      <form className="flex items-center flex-col justify-center bg-gray-200 shadow-xl rounded-lg p-8">
        <div className="mb-1">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Group/Label Name
            </label>
          </div>
          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={label?.rtsvrf_label_name ?? ""}
            onChange={(e) => handleForm("rtsvrf_label_name", e.target.value)}
            placeholder="Enter Group/Label Name..."
          />
        </div>

        <div className="mb-1">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Group/Label Code
            </label>
          </div>

          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={label?.rtsvrf_label_code ?? ""}
            onChange={(e) => handleForm("rtsvrf_label_code", e.target.value)}
            placeholder="Enter Group/Label Code..."
          />
        </div>
        <div className="mb-1">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Group/Label Contact Email
            </label>
          </div>
          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="email"
            value={label?.rtsvrf_label_contact_email ?? ""}
            onChange={(e) =>
              handleForm("rtsvrf_label_contact_email", e.target.value)
            }
            placeholder="Enter Contact Email..."
          />
        </div>
        <div class="mb-1 w-full">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Group/Label Type
            </label>
          </div>

          <div class="w-full">
            <select
              onChange={(e) => handleForm("group_type", e.target.value)}
              defaultValue={label?.group_type ?? ""}
              placeholder="Select a Group Type"
              class="block appearance-none text-xs w-full bg-white border border-gray-200 text-black py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            >
              <option value={""} disabled>
                Select a type
              </option>
              <option value={"label"}>Label</option>
              <option value={"festival"}>Festival</option>
              <option value={"initiative"}>Initiative</option>
              <option value={"venue"}>Venue</option>
              <option value={"project"}>Project</option>
              <option value={"collab"}>Collab</option>
            </select>
          </div>
        </div>
        <div className="mb-1">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Group/Label Link
            </label>
          </div>
          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={label?.group_link ?? ""}
            onChange={(e) => handleForm("group_link", e.target.value)}
            placeholder="Enter Link..."
          />
        </div>
        <div className="mb-1">
          <div className="flex items-center justify-start">
            <label className="block tracking-wide text-gray-500 text-xs font-bold mb-2">
              Group/Label Location
            </label>
          </div>
          <input
            className="appearance-none text-xs block w-80 font-xs bg-white text-black border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
            type="text"
            value={label?.group_location ?? ""}
            onChange={(e) => handleForm("group_location", e.target.value)}
            placeholder="Enter City..."
          />
        </div>
      </form>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={(e) => handleSubmit(e)}
          className="w-40 my-2 bg-black hover:bg-gray-800 text-white font-bold py-2 px-4 border border-gray-700 rounded"
        >
          {loading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
}

export default CreateLabelForm;
